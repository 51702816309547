import axios from "axios";

/**
 * 关注/取消关注某个对象
 * @param accountId
 * @returns {Promise<AxiosResponse<T>>}
 */
export const saveCollect = (accountId) => axios.post("/run/eval/saveCollect", {accountId: accountId})


/**
 * 给某人送礼的操作
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const giveGift = (params) => axios.post("/run/eval/giveGift", params);


/**
 * 对某人的评论操作
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const saveComment = (params) => axios.post("/run/eval/saveComment", params);
