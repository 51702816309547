<template>
	
	
	
  <el-dialog
      title="评论列表"
      :visible.sync="dialogVisible"
      width="1000px"
  >
    <div class="weekBtn">
	
	
	<el-row :gutter="40">
	  <el-col :span="8" v-for="i in list" :key="i" style="width: 100%!important; border-bottom: 1px solid #33d0dc;" >
	    <div class="item" >
	      <el-row>
	        <el-col :span="7" class="text-center margin-top">
	          <!-- <el-image :src="i.h_url" fit="fill"></el-image> -->
	        </el-col>
	        <el-col :span="17">
	          <h3>{{i.uname}}   评论时间：{{i.create_time  | formatTime('yyyy-MM-dd hh:mm:ss') }}
	           <!-- <small>带人咨询</small> -->
	          </h3> 
	        </el-col>
	      </el-row>
	      <p class="desc">
	        评论内容：{{i.message}}
	      </p>
		  <br/>
		  <br/>
	
	    </div>
	  </el-col>
	</el-row>
	
    </div>
	


  </el-dialog>
  
</template>

<script>
	import axios from "axios";
  export default {
    name: "Evaluate",
    props: ['info'],
    data() {
      return {
        dialogVisible: false,
		id:0,
		textarea:'',
		finfo:{},
		name:'',
		telphone:"",
		email:"",
		list:""
      }
    },
	 watch: {
	      info: function(newVal,oldVal){
	        this.finfo = newVal; //newVal即是chartData
	        this.drawChart(); 
	      },
		  
		  
	    },
		
		created(){
			
			var query=this.$route.query;
			
			this.id = query.id; 
			 
			const params = new URLSearchParams(); 
		    params.append('uid', this.id);
			console.log(params);
			axios.post(this.apiUrl+'/api/user/getEvaluate',params)
			.then((response) => {   
				this.list = response.data.data; 
			}) 
			.catch(function(error) {
			  console.log(error);
			});
			
				   
		},
		
	methods: {
	  open() {
	    this.dialogVisible = true
	  },
	  handleSend2(){  
		  
		  const params = new URLSearchParams();
		  
		  params.append('uid', sessionStorage.getItem('uid')); 
		  params.append('bid', this.finfo.id); 
		  params.append('name', this.name); 
		  params.append('email', this.email); 
		  params.append('tel', this.telphone);  
		  params.append('content', this.textarea);  
		  
		  axios.post(this.apiUrl+'/api/user/setReport', params)
		  .then((response) => { 	
		  		this.$message.success('操作成功');
		  		     window.location.reload();	
		    })
		    .catch(function(error) {
		      console.log(error);
		    });
		  		
	  }
	}
  }
</script>

<style scoped lang="scss">
  .weekBtn{
    .el-button{
      border-radius: 4px!important;
    }
  }

  /deep/ .el-dialog__header{
    background-color: $green;
    height: 33px;
    padding: 0 0 0 27px;
    .el-dialog__title{
      line-height: 33px;
      font-size: 12px;
      color: #fff;
    }
    .el-dialog__headerbtn{
      top: 8px;
      .el-dialog__close{
        color: #fff;
      }
    }
  }
  /deep/ .el-dialog__body{
    padding: 50px 80px;
  }
  /deep/ .el-dialog__footer{
    text-align: center;
    .el-button--primary{
      width: 95px;
      height: 32px;
      background-color: $green;
      border-color: $green;
    }
  }
</style>
