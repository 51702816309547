<template>
  <el-dialog
      title="举报中心"
      :visible.sync="dialogVisible"
      width="1000px"
  >
    <div class="weekBtn">
	<el-form ref="form" :rules="rules" :model="form" label-width="100px" label-suffix="：">
		
		
		  <el-form-item label="您的姓名" prop="name">
			<el-input v-model="name"> </el-input>
		  </el-form-item>
		  <el-form-item label="您的电话" prop="telphone">
			<el-input v-model="tel" prop="tel"></el-input>
		  </el-form-item>
		  <el-form-item label="您的邮箱" prop="email">
			<el-input v-model="email"></el-input>
		  </el-form-item>
		 
		  
		  
		  <el-form-item label="输入投诉内容" prop="email">
			   <el-input
				   type="textarea"
				   :rows="2"
				   placeholder=""
				   v-model="textarea"
				   style="margin-top: 10px"
			   /> 
		  </el-form-item>
	   
	   </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleSend2">发送</el-button>
    </span>
  </el-dialog>
  
</template>

<script>
	import axios from "axios";
  export default {
    name: "Evaluate",
    props: ['info'],
    data() {
      return {
        dialogVisible: false,
		id:0,
		textarea:'',
		finfo:{},
		name:'',
		tel:"",
		email:"",
		datas:{},
      }
    },
	
	
	    watch: {
	      info: function(newVal,oldVal){
	        this.finfo = newVal; //newVal即是chartData
	        this.drawChart();
	      }
	    }, 
		
		
		created(){
			var query=this.$route.query; 
			
			const params = new URLSearchParams(); 
			
			params.append('uid', sessionStorage.getItem('uid'));
			params.append('bid', query.did); 
			 
			axios.post(this.apiUrl+'/api/user/getReport',params)
			.then((response) => {  
				this.datas = response.data.data;   
				this.name = response.data.data.name;
				this.tel = response.data.data.tel;
				this.email = response.data.data.email;
				this.textarea = response.data.data.content; 
			}) 
			.catch(function(error) {
			  console.log(error);
			}); 
			   
		},
		
		

	methods: {
	  open() {
	    this.dialogVisible = true
	  },
	  handleSend2(){  
		  
		  const params = new URLSearchParams();
		  
		  params.append('uid', sessionStorage.getItem('uid')); 
		  params.append('bid', this.finfo.id); 
		  params.append('name', this.name); 
		  params.append('email', this.email); 
		  params.append('tel', this.tel);  
		  params.append('content', this.textarea);  
		  
		  axios.post(this.apiUrl+'/api/user/setReport', params)
		  .then((response) => { 	
		  		this.$message.success('操作成功');
		  		window.location.reload();	 
		    })
		    .catch(function(error) {
		      console.log(error);
		    });
		  		
	  }
	}
  }
</script>

<style scoped lang="scss">
  .weekBtn{
    .el-button{
      border-radius: 4px!important;
    }
  }

  /deep/ .el-dialog__header{
    background-color: $green;
    height: 33px;
    padding: 0 0 0 27px;
    .el-dialog__title{
      line-height: 33px;
      font-size: 12px;
      color: #fff;
    }
    .el-dialog__headerbtn{
      top: 8px;
      .el-dialog__close{
        color: #fff;
      }
    }
  }
  /deep/ .el-dialog__body{
    padding: 50px 80px;
  }
  /deep/ .el-dialog__footer{
    text-align: center;
    .el-button--primary{
      width: 95px;
      height: 32px;
      background-color: $green;
      border-color: $green;
    }
  }
</style>
