<template>
  <el-dialog
      title="留言内容"
      :visible.sync="dialogVisible"
      width="1000px"
  >
    <div class="weekBtn">
  
       <el-input
           type="textarea"
           :rows="2"
           placeholder="输入祝福语"
           v-model="textarea"
           style="margin-top: 10px"
       /> 
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleSend2">发送</el-button>
    </span>
  </el-dialog>
</template>

<script>
	import axios from "axios";
  export default {
    name: "Evaluate",
    props: ['info'],
    data() {
      return {
        dialogVisible: false,
		id:0,
		textarea:'',
		finfo:{}
      }
    },
	 watch: {
	      info: function(newVal,oldVal){
	        this.finfo = newVal; //newVal即是chartData
	        this.drawChart();
	      }
	    },

	methods: {
	  open() {
	    this.dialogVisible = true
	  },
	  handleSend2(){
		 
		  const params = new URLSearchParams();
		  params.append('uid', sessionStorage.getItem('uid')); 
	 
		  params.append('bid', this.finfo.id); 
	 
		  params.append('message', this.textarea); 
	 
		  
		  axios.post(this.apiUrl+'/api/user/setEvaluate', params)
		  .then((response) => { 	
		  		this.$message.success('操作成功');
		  		     window.location.reload();	
		    })
		    .catch(function(error) {
		      console.log(error);
		    });
		  		
	  }
	}
  }
</script>

<style scoped lang="scss">
  .weekBtn{
    .el-button{
      border-radius: 4px!important;
    }
  }

  /deep/ .el-dialog__header{
    background-color: $green;
    height: 33px;
    padding: 0 0 0 27px;
    .el-dialog__title{
      line-height: 33px;
      font-size: 12px;
      color: #fff;
    }
    .el-dialog__headerbtn{
      top: 8px;
      .el-dialog__close{
        color: #fff;
      }
    }
  }
  /deep/ .el-dialog__body{
    padding: 50px 80px;
  }
  /deep/ .el-dialog__footer{
    text-align: center;
    .el-button--primary{
      width: 95px;
      height: 32px;
      background-color: $green;
      border-color: $green;
    }
  }
</style>
