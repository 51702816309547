<template>
  <el-dialog
      title="挂号预约"
      :visible.sync="dialogVisible"
      width="1000px"
  >
    <div class="weekBtn">
      <el-button size="mini">本周预约</el-button>
      <el-button size="mini">下周预约</el-button>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleSend">赠送</el-button>
    </span>
  </el-dialog>
</template>

<script>
  export default {
    name: "Schedual",
    data() {
      return {
        dialogVisible: false
      }
    }
  }
</script>

<style scoped lang="scss">
  .weekBtn{
    .el-button{
      border-radius: 4px!important;
    }
  }

  /deep/ .el-dialog__header{
    background-color: $green;
    height: 33px;
    padding: 0 0 0 27px;
    .el-dialog__title{
      line-height: 33px;
      font-size: 12px;
      color: #fff;
    }
    .el-dialog__headerbtn{
      top: 8px;
      .el-dialog__close{
        color: #fff;
      }
    }
  }
  /deep/ .el-dialog__body{
    padding: 50px 80px;
  }
  /deep/ .el-dialog__footer{
    text-align: center;
    .el-button--primary{
      width: 95px;
      height: 32px;
      background-color: $green;
      border-color: $green;
    }
  }
</style>
